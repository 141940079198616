<template>
  <div class="elv-transaction-rule-tabBar-container" @click="onChangeTab($event)">
    <div
      class="elv-transaction-rule-tabBar-pane"
      :class="{ 'is-active': props.activeTab === 'transfer' }"
      aria-controls="transfer"
    >
      {{ t('report.transfer')
      }}<SvgIcon name="rule-arrow-right" class="elv-transaction-rule-tabBar-pane__arrow" width="12" height="12" />{{
        t('report.journals')
      }}
    </div>
    <div
      class="elv-transaction-rule-tabBar-pane"
      :class="{ 'is-active': props.activeTab === 'trade' }"
      aria-controls="trade"
    >
      {{ t('common.trade')
      }}<SvgIcon name="rule-arrow-right" class="elv-transaction-rule-tabBar-pane__arrow" width="12" height="12" />{{
        t('report.journals')
      }}
    </div>
    <div
      class="elv-transaction-rule-tabBar-pane"
      :class="{ 'is-active': props.activeTab === 'gainLoss' }"
      aria-controls="gainLoss"
    >
      {{ t('project.costBasis.gainOrLoss')
      }}<SvgIcon name="rule-arrow-right" class="elv-transaction-rule-tabBar-pane__arrow" width="12" height="12" />{{
        t('report.journals')
      }}
    </div>
    <div
      class="elv-transaction-rule-tabBar-pane"
      :class="{ 'is-active': props.activeTab === 'businessData' }"
      aria-controls="businessData"
    >
      {{ t('report.businessData')
      }}<SvgIcon name="rule-arrow-right" class="elv-transaction-rule-tabBar-pane__arrow" width="12" height="12" />{{
        t('report.journals')
      }}
    </div>
    <div
      class="elv-transaction-rule-tabBar-pane"
      :class="{ 'is-active': props.activeTab === 'valuation' }"
      aria-controls="valuation"
    >
      {{ t('report.businessData')
      }}<SvgIcon name="rule-arrow-right" class="elv-transaction-rule-tabBar-pane__arrow" width="12" height="12" />{{
        t('menus.valuation')
      }}
    </div>
    <div
      class="elv-transaction-rule-tabBar-pane"
      :class="{ 'is-active': props.activeTab === 'counterpartyTransformer' }"
      aria-controls="counterpartyTransformer"
    >
      {{ t('report.identifyCounterparty') }}
    </div>
  </div>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n'

const props = defineProps({
  activeTab: {
    type: String,
    default: 'transaction'
  }
})

const { t } = useI18n()

const emit = defineEmits(['onChangeTab'])

const onChangeTab = (e: any) => {
  const { target } = e
  const tab = target.getAttribute('aria-controls')
  if (tab) {
    emit('onChangeTab', tab)
  }
}
</script>

<style lang="scss" scoped>
.elv-transaction-rule-tabBar-container {
  display: flex;
  align-items: flex-start;
  align-content: flex-start;
  gap: 16px;
  flex: 1 0 0;
  flex-wrap: wrap;
  @media screen and (max-width: 1440px) {
    max-width: 966px;
  }

  .elv-transaction-rule-tabBar-pane {
    display: flex;
    height: 27px;
    padding: 0px 16px;
    box-sizing: border-box;
    align-items: center;
    justify-content: center;
    border-radius: 20px;
    border: 1px solid #e5edff;
    background: #fff;
    color: #636b75;
    font-family: 'Plus Jakarta Sans';
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    cursor: pointer;
    transition: all 0.1s;
    white-space: nowrap;

    &:not(.is-active):hover {
      border: 1px solid #5e85eb;

      .elv-transaction-rule-tabBar-pane__arrow {
        fill: #5e85eb;
      }
    }

    &.is-active {
      background: #1753eb;
      color: #fff;
      border: 1px solid #1753eb;

      .elv-transaction-rule-tabBar-pane__arrow {
        fill: #fff;
      }
    }

    .elv-transaction-rule-tabBar-pane__logo {
      display: block;
      width: 14px;
      height: 14px;
      margin-right: 4px;
    }

    .elv-transaction-rule-tabBar-pane__arrow {
      margin: 0 4px;
      fill: #636b75;
      margin-top: 2px;
    }
  }
}
</style>
